import React, { useState, useContext } from "react";
import "./sidebar.css";
import { SettingsContext } from "../DataFactory/useSettings";
import GNestedMenu from "../Elements/gNestedMenu";
import {
  ImgUrl,
  ApiService,
  addDefaultLogo,
  isStoreManager,
  isPermissionExist,
} from "../services";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Mail } from "../assets/images/sidebar/mail.svg";
import { ReactComponent as Phone } from "../assets/images/sidebar/phone.svg";
import { ReactComponent as WhatsApp } from "../assets/images/sidebar/whatsapp.svg";
import { ReactComponent as ChatIcon } from "../assets/images/sidebar/chat.svg";
import { ReactComponent as PlayIcon } from "../assets/images/icons/play.svg";
import { ReactComponent as PauseIcon } from "../assets/images/icons/pause.svg";
import Modal from "react-modal";
import PromotionForm from "../forms/promotionForm";
import GConfirmDialog from "../Elements/gConfirmDialog";
import StoreAvailabilityForm from "../forms/storeAvailabilityForm";
import { useSnackbar } from "notistack";

const SupportUl = styled.ul`
  margin: 0 15px !important;
  padding: 2px 0 5px !important;
  background: #444;
  li {
    padding: 4px 10px !important;
    a {
      color: #ccc !important;
      font-size: 13px !important;
      align-items: center;
      display: inline-flex !important;
      width: 100% !important;
      padding: 2px 0 !important;

      svg {
        width: 15px;
        height: 15px;
        padding-right: 10px;
        fill: #f5f5f5;
      }
    }
  }
`;

const SupportSUI = styled.ul`
  display: flex;
  margin: 10px 15px !important;
  padding: 2px 0 5px !important;
  li {
    padding: 4px 10px !important;
    text-align: center;
    width: 50%;
    a {
      color: #ccc !important;
      font-size: 13px !important;
      align-items: center;
      justify-content: center;
      display: flex !important;
      width: 100% !important;
      padding: 2px 0 !important;
      flex-direction: column;
      gap: 5px;
      cursor: pointer;

      svg {
        width: 15px;
        height: 15px;
        padding: 2px 8px;
        fill: #f5f5f5;
        background: #333;
        border-radius: 20px;
      }
    }
  }
`;

const Bottom = styled.div`
  width: 18%;
  padding: 10px 15px;
  position: fixed;
  bottom: 0;
  font-size: 14px;
  left: 0;
  box-sizing: border-box;
  color: #fff;
`;

export default function Sidebar(props) {
  let location = useLocation();
  const { settings, setSettings, refreshSettings } =
    useContext(SettingsContext);
  const [ccpnvisible, setCcpnvisible] = useState(false);
  const [showSAvaiChangeForm, setShowSAvaiChangeForm] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const toggleStatus = () => {
    ApiService({
      method: "PUT",
      route: "stores/" + settings?.store?.id,
      body: { store_status: 1 },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          settings.store = response.data.data;
          setSettings({ ...settings });
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="Sidebar" style={props.style}>
      <div className="App-header">
        {isStoreManager() || isPermissionExist("dashboard_index") ? (
          <Link to="/dashboard">
            <img
              src={ImgUrl("logo") + "/" + settings?.admin_logo}
              className="App-logo"
              alt="logo"
              onError={addDefaultLogo}
            />
          </Link>
        ) : (
          <img
            src={ImgUrl("logo") + "/" + settings?.admin_logo}
            className="App-logo"
            alt="logo"
            onError={addDefaultLogo}
          />
        )}
        <span>
          <h3>{settings.project_name}</h3>
        </span>
      </div>

      <GNestedMenu
        settings={settings}
        handleClick={(title) => {
          // eslint-disable-next-line no-unused-expressions
          title === "Promotions" ? setCcpnvisible(true) : "";
        }}
      />

      <h4>Support</h4>
      <ul className="pList" style={{ margin: "0 0 8px" }}>
        <li className={location.state === "support" ? "active" : ""}>
          <Link to={{ pathname: "/chat-support" }} state="support">
            <span role="img" aria-label="help" className="icon">
              <ChatIcon
                style={{
                  width: "17px",
                  height: "17px",
                  fill: location.state === "support" ? "#fff" : "#bbb",
                }}
              />
            </span>
            <span>Chat Support</span>
          </Link>
        </li>
      </ul>

      <SupportUl>
        {settings?.admin_email && (
          <li>
            <a href={"mailTo:" + settings?.admin_email}>
              <Mail /> {settings?.admin_email}
            </a>
          </li>
        )}
        {/* {settings?.admin_whatsapp && (
          <li>
            <a
              target="_blank"
              href={
                "https://api.whatsapp.com/send?phone=" +
                settings?.admin_whatsapp
              }
            >
              <WhatsApp /> {settings?.admin_whatsapp}
            </a>
          </li>
        )} */}
      </SupportUl>
      <SupportSUI>
        <li style={{ width: isStoreManager() == true ? "50%" : "100%" }}>
          <a href={"tel:" + settings?.admin_phone}>
            <Phone />
            <span>Contact Support</span>
          </a>
        </li>
        {isStoreManager() ? (
          <li>
            {settings?.store?.store_status == 0 ? (
              <a onClick={() => setDialogOpen(true)}>
                <PlayIcon
                  style={{ width: 15, height: 13, padding: "4px 10px" }}
                />
                <span>Resume New Orders</span>
              </a>
            ) : (
              <a onClick={() => setShowSAvaiChangeForm(true)}>
                <PauseIcon
                  style={{ width: 15, height: 13, padding: "4px 10px" }}
                />

                <span>Pause New Orders</span>
              </a>
            )}
          </li>
        ) : (
          ""
        )}
      </SupportSUI>

      <Bottom
        style={{
          background:
            settings?.store?.store_status == 0 ? "#B28218" : "#347107",
        }}
      >
        {settings?.store?.store_status == 0
          ? "❚❚  Paused"
          : "●  Accepting Orders"}
      </Bottom>

      <Modal
        isOpen={ccpnvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcpnvisible(false)}
        style={{
          content: {
            width: "600px",
          },
        }}
      >
        <PromotionForm
          ccpnvisible={ccpnvisible}
          setCcpnvisible={setCcpnvisible}
          isEmpty={true}
        />
      </Modal>

      <Modal
        isOpen={showSAvaiChangeForm}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowSAvaiChangeForm(false)}
        style={{
          content: {
            width: "550px",
          },
        }}
        contentLabel="Pause new orders form Modal"
      >
        <StoreAvailabilityForm
          showSAvaiChangeForm={showSAvaiChangeForm}
          setShowSAvaiChangeForm={setShowSAvaiChangeForm}
          onSubmit={() => {
            refreshSettings();
          }}
        />
      </Modal>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Resume New Orders"
          text="Are you sure you want to Resume new orders?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            toggleStatus();
          }}
        ></GConfirmDialog>
      )}
    </div>
  );
}
