import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@mui/material";
import { ApiService } from "../services";
import { ReactComponent as Edit } from "../assets/images/icons/edit.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { useLocation, useNavigate } from "react-router-dom";
import GEmpty from "../Elements/gEmpty";
import GTableShimmer from "../Elements/gTableShimmer";
import GButton from "../Elements/GButton";
import GConfirmDialog from "../Elements/gConfirmDialog";
import GPagination from "../Elements/gPagination";
import GPaginationSelect from "../Elements/gPaginationSelectbox";
import GSwitch from "../Elements/gSwitch";
import PromotionForm from "../forms/promotionForm";
import GSelectbox from "../Elements/gSelectbox";
import QueryWith from "../Query";
import { useSnackbar } from "notistack";
import Modal from "react-modal";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";

const columns = [
  { id: "title", label: "Title", minWidth: 130 },
  { id: "promotion_type", label: "Promotion Type", minWidth: 50 },
  { id: "coupon_code", label: "Coupon Code", minWidth: 120 },
  { id: "time_schedule", label: "Time Schedule", minWidth: 150 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "added_by", label: "Added By", minWidth: 50, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];
const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

export default function Promotions() {
  const { enqueueSnackbar } = useSnackbar();
  const [strings] = useContext(StringsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [couponData, setCouponData] = useState([]);
  const [couponPaginationData, setCouponPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCouponData, setSelectedCouponData] = useState({
    index: "",
    data: {},
  });
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [ccpnvisible, setCcpnvisible] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    status: params.get("status") ? params.get("status") : "",
  });

  const promotionTypes = [
    {
      title: strings.w_free_delivery,
      value: "free_delivery",
    },
    { title: strings.w_free_product, value: "free_product" },
    {
      title: strings.w_fixed_discount,
      value: "fixed_discount",
    },
    {
      title: strings.w_percentage_discount,
      value: "percentage_discount",
    },
  ];

  const getCoupons = () => {
    var params = QueryWith(location, filters);
    if (params.length > 0) {
      navigate(
        {
          pathname: "/coupons",
          search: params[0],
        },
        { state: "coupon" }
      );
    }

    ApiService({ method: "GET", route: "coupons", body: filters })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setCouponData(response.data.data.data);
          setCouponPaginationData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCoupons();
  }, [filters]);

  const deleteCoupon = () => {
    ApiService({ method: "DELETE", route: "coupons/" + selectedCouponId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getCoupons();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleValue = (newValue, valueId, i) => {
    ApiService({
      method: "PUT",
      route: "coupons/" + valueId,
      body: { status: newValue },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          couponData[i].status = newValue;
          setCouponData([...couponData]);
        } else {
          getCoupons();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <GText g1="true" bold text={"Promotions"} />

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
          margin: "15px 0",
        }}
      >
        <GSelectbox
          placeholder="Select Status"
          defaultvalue={filters.status}
          data={statusFilters}
          id="item_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />
        {isLoading == false && couponPaginationData ? (
          <>
            <GPagination
              style={{ display: "inline-block", marginLeft: "auto" }}
              count={couponPaginationData.total}
              from={couponPaginationData.from || 0}
              to={couponPaginationData.to || 0}
              onChangeNextPage={() =>
                handleChangePage(couponPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(couponPaginationData.prev_page_url)
              }
              rowsPerPage={couponPaginationData.per_page}
              prevDisabled={couponPaginationData.prev_page_url ? false : true}
              nextDisabled={couponPaginationData.next_page_url ? false : true}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={couponPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </>
        ) : (
          ""
        )}
      </div>

      <div className="listPaper" style={{ marginTop: 5 }}>
        {isLoading == true ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {couponData && couponData.length > 0 ? (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {couponData.map((c, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        <TableRowData>
                          {" "}
                          {c.promotion_type
                            ? promotionTypes
                                .filter(
                                  (type) => type.value == c.promotion_type
                                )
                                .map((t) => {
                                  return t.title;
                                })
                            : "-"}{" "}
                        </TableRowData>
                        <TableRowData>{c.code || "-"}</TableRowData>
                        <TableRowData>
                          Valid From :{" "}
                          {c.valid_from ? (
                            c.valid_from
                          ) : (
                            <span style={{ color: "#a2a2a2" }}>NA</span>
                          )}
                          <br />
                          Valid Upto :{" "}
                          {c.valid_upto ? (
                            c.valid_upto
                          ) : (
                            <span style={{ color: "#a2a2a2" }}>NA</span>
                          )}
                        </TableRowData>
                        <TableRowData>
                          <GSwitch
                            id={c.id}
                            uId={c.id}
                            disabled={c.created_by === "admin" ? true : false}
                            currentValue={
                              c.status == "true" || c.status == "1" ? 1 : 0
                            }
                            onSwitchChange={(newVal, id) =>
                              toggleValue(newVal, id, index)
                            }
                          />
                        </TableRowData>

                        <TableRowData
                          style={{
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          {c.created_by || "-"}
                        </TableRowData>

                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created_by == "admin" ? (
                            "-"
                          ) : (
                            <>
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedCouponData({
                                    index: index,
                                    data: c,
                                  });
                                  setCcpnvisible(true);
                                }}
                              >
                                <Edit style={{ width: 22, height: 22 }} />
                              </GButton>

                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  setSelectedCouponId(c.id);
                                  setDialogOpen(true);
                                }}
                              >
                                <Delete style={{ width: 22, height: 22 }} />
                              </GButton>
                            </>
                          )}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <GEmpty></GEmpty>
            )}
          </TableContainer>
        )}
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Promotion"
          text="Are you sure you want to delete this promotion?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCoupon();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={ccpnvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcpnvisible(false)}
        style={{
          content: {
            width: "600px",
          },
        }}
      >
        <PromotionForm
          ccpnvisible={ccpnvisible}
          setCcpnvisible={setCcpnvisible}
          data={selectedCouponData.data}
          isEmpty={false}
          onSubmit={(data) => {
            console.log(data);
            if (data) {
              couponData[selectedCouponData.index] = data;
            }
          }}
        />
      </Modal>
    </div>
  );
}
