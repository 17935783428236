import React, { useEffect, useState, useContext } from "react";
import { useSnackbar } from "notistack";
import { ApiService, isStoreManager, isPermissionExist } from "../services";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import GConfirmDialog from "../Elements/gConfirmDialog";
import CategoryForm from "../forms/productCategoryForm";
import GListCard from "../Elements/gListCards";
import useDataFactory from "../useDataFactory";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";
import useSettings from "../useSettings";

export default function Categories() {
  const { enqueueSnackbar } = useSnackbar();
  const [strings] = useContext(StringsContext);
  const settings = useSettings();
  const [catFormvisible, setCatFormvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState({});

  const {
    loading,
    data: categoriesData,
    refreshData,
    Shimmer,
    Placeholder,
  } = useDataFactory("product/group", false);

  const [catData, setCatData] = useState(categoriesData || []);

  useEffect(() => {
    setCatData(categoriesData || []);
  }, [categoriesData]);

  const deleteCategory = (id) => {
    var catId = id;
    ApiService({ method: "DELETE", route: "product/categories/" + catId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditCtaegory = (data) => {
    setSelectedCategoryData(data);
    setIsEmpty(false);
    setCatFormvisible(true);
  };

  const handleToggleStatus = (newVal, id, ind) => {
    console.log(newVal);
    ApiService({
      method: "PUT",
      route: "product/categories/" + id,
      body: {
        status: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          catData[ind] = response.data.data;
          setCatData([...catData]);
        } else {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView" style={{ padding: "20px 20px 0" }}>
      {isStoreManager() || isPermissionExist("product_store") ? (
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedCategoryData({});
            setIsEmpty(true);
            setCatFormvisible(true);
          }}
          style={{
            float: "right",
          }}
        />
      ) : (
        ""
      )}

      <GText
        g1={"true"}
        bold
        text={"Categories"}
        style={{ margin: "0 0 20px", fontSize: "27px" }}
      />

      <div className="listPaper">
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            padding: "0 0 60px",
          }}
        >
          {loading ? (
            <Shimmer />
          ) : catData && catData.length > 0 ? (
            catData.map((c, pi) => {
              return (
                <React.Fragment key={c.id}>
                  <GListCard
                    setting={strings.w_category}
                    id={c.id}
                    title={c.title}
                    subtitle={
                      c.item_count +
                      (c.item_count > 1
                        ? " " + strings.w_items
                        : " " + strings.w_item)
                    }
                    switch={
                      isStoreManager() || isPermissionExist("product_update")
                        ? true
                        : false
                    }
                    switchValue={c.status}
                    dropdownActions={
                      isStoreManager() ||
                      isPermissionExist("product_update") ||
                      isPermissionExist("product_destroy")
                        ? true
                        : false
                    }
                    edit={
                      isStoreManager() || isPermissionExist("product_update")
                        ? true
                        : false
                    }
                    delete={
                      isStoreManager() || isPermissionExist("product_destroy")
                        ? true
                        : false
                    }
                    onEdit={handleEditCtaegory}
                    onDelete={(id) => {
                      deleteCategory(id);
                    }}
                    data={c}
                    onSwitchToggle={(val, id) =>
                      handleToggleStatus(val, id, pi)
                    }
                    style={{ width: "23%" }}
                    titleStyle={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      height: "22px",
                    }}
                  ></GListCard>
                </React.Fragment>
              );
            })
          ) : (
            <Placeholder />
          )}
        </div>
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_category_title}
          text={strings.s_delete_category_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCategory();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={catFormvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCatFormvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Category Form Modal"
      >
        <CategoryForm
          catFormvisible={catFormvisible}
          setCatFormvisible={setCatFormvisible}
          data={selectedCategoryData}
          isEmpty={isEmpty}
          settings={settings}
          onSubmit={() => refreshData()}
          onLocalizationRemove={(ind) => {
            let findInd = categoriesData.findIndex(
              (nId) => nId?.id == selectedCategoryData.id
            );

            if (findInd > -1) {
              categoriesData[findInd].localizations.splice(ind, 1);
            }
          }}
          onLocalizationUpdate={(ind, data) => {
            let findInd = categoriesData.findIndex(
              (nId) => nId?.id == selectedCategoryData.id
            );

            if (findInd > -1) {
              categoriesData[findInd].localizations[ind] = data;
            }
          }}
        />
      </Modal>
    </div>
  );
}
