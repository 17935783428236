import React, { useState } from "react";
import styled from "styled-components";
import GConfirmDialog from "./gConfirmDialog";

const Ul = styled.ul`
  display: inline-flex;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
`;
const Li = styled.li`
  background: #d3d9e5;
  color: #747d8c;
  padding: 7px 0 7px 25px;
  text-align: center;
  font-size: 14px;
  position: relative;
  display: block;
  float: left;
  &:not(:last-child):before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid white;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  &:not(:last-child):after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 100%;
    z-index: 2;
    border-left: 13px solid #d3d9e5;
  }
  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &.Completed,
  &.colored {
    background: #d8ae36;
    color: #fff;
  }
  &.Completed:not(:last-child):after,
  &.colored:after {
    border-left: 14px solid #d8ae36;
  }
  &.Cancelled {
    background: #e88689;
    color: #fff;
  }
  &.Cancelled:not(:last-child):after {
    border-left: 14px solid #e88689;
  }
`;

export default function CustomSeparator(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  function handleClick() {
    let orderStatus =
      selectedStatus === "accepted"
        ? "accept"
        : selectedStatus === "completed"
        ? "complete"
        : selectedStatus === "started"
        ? "start"
        : selectedStatus;
    props.onUpdateStatus(orderStatus);
  }

  return (
    <div>
      {props.class !== "Failed" && props.class !== "Cancelled" ? (
        <Ul>
          {props.statusList
            .filter(
              (status) =>
                status.identifier !== "failed" &&
                status.identifier !== "cancelled"
            )
            .map((s) => {
              return (
                <Li
                  style={{
                    width:
                      100 /
                        props.statusList.filter(
                          (status) =>
                            status.identifier !== "failed" &&
                            status.identifier !== "cancelled"
                        ).length +
                      "%",
                    cursor: !props.updateStatus
                      ? "auto"
                      : props.class === "Completed"
                      ? "auto"
                      : s.identifier === "pending" ||
                        s.identifier === "cancelled" ||
                        s.title === props.class
                      ? "auto"
                      : "pointer",
                  }}
                  // onClick={handleClick}
                  key={s.identifier}
                  className={(props.class, s.color)}
                  onClick={() => {
                    if (!props.updateStatus) {
                      return;
                    }
                    if (props.class === "Completed") {
                      return;
                    }
                    if (
                      s.identifier === "pending" ||
                      s.identifier === "cancelled" ||
                      s.title === props.class
                    ) {
                      return;
                    }
                    setSelectedStatus(s.identifier);
                    setDialogOpen(true);
                  }}
                >
                  {s.title}
                </Li>
              );
            })}
        </Ul>
      ) : (
        <Ul>
          {props.statusList
            .filter(
              (status) =>
                status.identifier !== "completed" &&
                status.identifier !== "failed"
            )
            .map((s) => {
              return (
                <Li
                  style={{
                    width:
                      100 /
                        props.statusList.filter(
                          (status) =>
                            status.identifier !== "failed" &&
                            status.identifier !== "completed"
                        ).length +
                      "%",
                    cursor: !props.updateStatus
                      ? "auto"
                      : props.class === "Cancelled" || props.class === "Failed"
                      ? "auto"
                      : s.identifier === "pending" ||
                        s.identifier === "cancelled" ||
                        s.title === props.class
                      ? "auto"
                      : "pointer",
                  }}
                  key={s.identifier}
                  className={props.class}
                  onClick={() => {
                    if (!props.updateStatus) {
                      return;
                    }
                    if (
                      props.class === "Cancelled" ||
                      props.class === "Failed"
                    ) {
                      return;
                    }
                    if (
                      s.identifier === "pending" ||
                      s.identifier === "cancelled" ||
                      s.title === props.class
                    ) {
                      return;
                    }
                    setSelectedStatus(s.identifier);
                    setDialogOpen(true);
                  }}
                >
                  {s.title}
                </Li>
              );
            })}
        </Ul>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Update Status"
          text="Are you sure you want to update the status?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleClick();
          }}
        ></GConfirmDialog>
      )}
    </div>
  );
}
