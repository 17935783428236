import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "notistack";
import { GImagePicker } from "../Elements/gImagePicker";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";

export default function EditStoreProfile(props) {
  const { settings, setSettings } = useContext(SettingsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    title: settings?.store?.title || "",
    phone: settings?.store?.phone || "",
    photo: settings?.store?.photo || "",
    thumb_photo: settings?.store?.thumb_photo || "",
    sales_report: settings?.store?.sales_report || "",
    pack_time: settings?.store?.pack_time || "",
  };
  const { enqueueSnackbar } = useSnackbar();
  const [icon, setIcon] = useState("");

  const salesReportValues = [
    { title: "Daily", value: "daily" },
    { title: "Weekly", value: "weekly" },
    { title: "Monthly", value: "monthly" },
  ];

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);

    ApiService({
      method: "PUT",
      route: "stores/" + settings?.store?.id,
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        settings.store = response.data.data;
        setSettings({ ...settings });
        props.setESpvisible(false);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    phone: Yup.string()
      .min("7", "Phone must be atleast 7 digits")
      .max("15", "Phone must not be greater than 15 digits")
      .required("Required"),
  });

  if (props.espvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">Edit Store Info</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GImagePicker
                type="store"
                alt={settings.store?.title}
                height={100}
                width={100}
                radius={50}
                editor
                editorProps={{
                  height: 200,
                  width: 200,
                  radius: 100,
                }}
                serverProps={{
                  size: 200,
                }}
                onSuccess={(v) => {
                  console.log(v);
                  setIcon(v.thumb_image);
                  values.photo = v.image;
                  values.thumb_photo = v.thumb_image;
                }}
                isPreviewImg={true}
                default={ImgUrl("store") + "/" + settings.store?.thumb_photo}
              />

              <br />

              <FormikControl
                control="input"
                type="text"
                label="Store Title"
                name="title"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("title", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Phone"
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />

              <FormikControl
                control="input"
                type="text"
                label="Packaging Time (in min)"
                name="pack_time"
                maxLength={4}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("pack_time", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
              />

              <FormikControl
                control="select"
                as="select"
                label="Sales Report"
                name="sales_report"
                placeholder={"Choose"}
                options={salesReportValues}
                key_title="title"
                key_value="value"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "50%",
                }}
              />

              <GButton
                type="submit"
                disabled={isBtnLoading}
                variant="condensed"
                children="Update"
                style={{ margin: "15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
