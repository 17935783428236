import React, { useState, useEffect, useContext } from "react";
import { Grid, TableContainer } from "@mui/material";
import { SettingsContext } from "../DataFactory/useSettings";
import {
  ApiService,
  isStoreManager,
  isPermissionExist,
  ImgUrl,
  addDefaultSrc,
} from "../services";
import { StringsContext } from "../DataFactory/useStrings";
import { GCardShimmer } from "../Elements/gShimmer";
import { Card } from "../styledComponents/cardStyles";
import GText from "../Elements/GText";
import GCharts from "../Elements/gCharts";
import GEmpty from "../Elements/gEmpty";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";

const paperStyle = {
  height: "410px",
  overflow: "auto",
  border: "1px solid #e8e8e8",
};

export default function Dashboard() {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const [dashboardData, setDashboardData] = useState({
    daily_orders_graph: [],
    daily_transactions_graph: [],
    least_selling_products: [],
    monthly_orders_graph: [],
    monthly_transactions_graph: [],
    most_selling_products: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const getDashboardData = () => {
    ApiService({ method: "GET", route: "dashboard" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDashboardData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  const itemColumns = [
    { id: "title", label: strings.w_title, minWidth: 70 },
    { id: "photo", label: strings.w_photo, minWidth: 50 },
    { id: "category", label: strings.w_category, minWidth: 60 },
    {
      id: "ranking_order",
      label: strings.w_count,
      minWidth: 70,
      align: "center",
    },
  ];

  return (
    <div className={"mainInnerView"}>
      <h1>Dashboard </h1>

      {isLoading == true ? (
        <GCardShimmer />
      ) : (
        <div style={{ flexGrow: 1 }}>
          {isStoreManager() || isPermissionExist("order_index") ? (
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <Card style={paperStyle}>
                  <GText
                    g2
                    bold
                    text={strings.w_daily_orders}
                    style={{ color: "#333", padding: "4px 0 30px 8px" }}
                  />
                  <GCharts
                    labels={
                      dashboardData?.daily_orders_graph &&
                      dashboardData?.daily_orders_graph.map((l) => {
                        return l.label;
                      })
                    }
                    values={
                      dashboardData?.daily_orders_graph &&
                      dashboardData?.daily_orders_graph.map((l) => {
                        return l.count;
                      })
                    }
                    height="320"
                    color="#46ca7c"
                    type="areaspline"
                    name="Order"
                  />
                </Card>
              </Grid>{" "}
              <Grid item lg={6} xs={12}>
                <Card style={paperStyle}>
                  <GText
                    g2
                    bold
                    text={strings.w_daily_revenue}
                    style={{ color: "#333", padding: "4px 0 4px 8px" }}
                  />
                  <GText
                    med
                    text={strings.s_including_all_tranactions}
                    style={{ padding: "0 0 30px 8px" }}
                  />
                  <GCharts
                    labels={
                      dashboardData?.daily_transactions_graph &&
                      dashboardData?.daily_transactions_graph.map((l) => {
                        return l.label;
                      })
                    }
                    values={
                      dashboardData?.daily_transactions_graph &&
                      dashboardData?.daily_transactions_graph.map((l) => {
                        return l.count;
                      })
                    }
                    height="290"
                    color="#e56d87"
                    type="line"
                    name="Transaction"
                  />
                </Card>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Card style={paperStyle}>
                  <GText
                    g2
                    bold
                    text={strings.w_orders}
                    style={{ color: "#333", padding: "4px 0 4px 8px" }}
                  />
                  <GText
                    med
                    text={strings.s_total_orders_for_this_month}
                    style={{ padding: "0 0 30px 8px" }}
                  />
                  <GCharts
                    labels={
                      dashboardData?.monthly_orders_graph &&
                      dashboardData?.monthly_orders_graph.map((l) => {
                        return l.label;
                      })
                    }
                    values={
                      dashboardData?.monthly_orders_graph &&
                      dashboardData?.monthly_orders_graph.map((l) => {
                        return l.count;
                      })
                    }
                    height="320"
                    color="#46ca7c"
                    type="areaspline"
                    name="Order"
                  />
                </Card>
              </Grid>{" "}
              <Grid item lg={6} xs={12}>
                <Card style={paperStyle}>
                  <GText
                    g2
                    bold
                    text={strings.w_monthly_revenue}
                    style={{ color: "#333", padding: "4px 0 4px 8px" }}
                  />
                  <GText
                    med
                    text={strings.s_including_all_tranactions}
                    style={{ padding: "0 0 30px 8px" }}
                  />
                  <GCharts
                    labels={
                      dashboardData?.monthly_transactions_graph &&
                      dashboardData?.monthly_transactions_graph.map((l) => {
                        return l.label;
                      })
                    }
                    values={
                      dashboardData?.monthly_transactions_graph &&
                      dashboardData?.monthly_transactions_graph.map((l) => {
                        return l.count;
                      })
                    }
                    height="290"
                    color="#e56d87"
                    type="line"
                    name="Transaction"
                  />
                </Card>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {isStoreManager() || isPermissionExist("product_index") ? (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Card
                  style={{
                    border: "1px solid #e8e8e8",
                  }}
                >
                  <GText
                    g2
                    bold
                    text={strings.w_best_selling_products}
                    style={{ color: "#333", paddingLeft: "8px" }}
                  />
                  <TableContainer
                    style={{
                      height: "450px",
                      overflow: "auto",
                      marginTop: "20px",
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {itemColumns.map((column) => (
                            <TableHeader
                              key={column.id}
                              style={{
                                minWidth: column.minWidth,
                                textAlign: column.align,
                              }}
                            >
                              {column.label}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      {dashboardData?.most_selling_products &&
                      dashboardData.most_selling_products.length > 0 ? (
                        <TableBody>
                          {dashboardData?.most_selling_products.map((o) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={o.id}
                                className="bordered"
                              >
                                <TableRowData>{o.title}</TableRowData>
                                <TableRowData>
                                  <img
                                    alt={o.title}
                                    src={ImgUrl("item") + "/" + o.thumb_photo}
                                    onError={addDefaultSrc}
                                    style={{
                                      height: "45px",
                                      width: "45px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </TableRowData>
                                <TableRowData>
                                  {o.categories_title}
                                </TableRowData>
                                <TableRowData style={{ textAlign: "center" }}>
                                  {o.ranking_order}
                                </TableRowData>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      ) : (
                        <GEmpty />
                      )}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card
                  style={{
                    border: "1px solid #e8e8e8",
                  }}
                >
                  <GText
                    g2
                    bold
                    text={strings.w_least_Selling_products}
                    style={{ color: "#333", paddingLeft: "8px" }}
                  />
                  <TableContainer
                    style={{
                      height: "450px",
                      overflow: "auto",
                      marginTop: "20px",
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {itemColumns.map((column) => (
                            <TableHeader
                              key={column.id}
                              style={{
                                minWidth: column.minWidth,
                                textAlign: column.align,
                              }}
                            >
                              {column.label}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      {dashboardData?.least_selling_products &&
                      dashboardData.least_selling_products.length > 0 ? (
                        <TableBody>
                          {dashboardData?.least_selling_products.map((o) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={o.id}
                                className="bordered"
                              >
                                <TableRowData>{o.title}</TableRowData>
                                <TableRowData>
                                  <img
                                    alt={o.title}
                                    src={ImgUrl("item") + "/" + o.thumb_photo}
                                    onError={addDefaultSrc}
                                    style={{
                                      height: "45px",
                                      width: "45px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </TableRowData>
                                <TableRowData>
                                  {o.categories_title}
                                </TableRowData>
                                <TableRowData style={{ textAlign: "center" }}>
                                  {o.ranking_order}
                                </TableRowData>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      ) : (
                        <GEmpty />
                      )}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
