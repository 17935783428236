import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import GButton from "../Elements/GButton";
import { GContent } from "../Elements/gContent";
import useDataFctory from "../useDataFactory";
import GView from "../Elements/GView";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { ReactComponent as Edit } from "../assets/images/icons/edit.svg";
import Modal from "react-modal";
import AssignRoleForm from "../forms/assignRoleForm";
import StaffForm from "../forms/staffForm";
import { useParams } from "react-router-dom";
import GInfo from "../Elements/gInfo";
import GText from "../Elements/GText";
import { TableContainer } from "@mui/material";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";

const columns = [
  { id: "ip", label: "IP Address", minWidth: 100, align: "left" },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 120,
    align: "center",
  },
];

export default function StaffDetail() {
  let { staffId } = useParams();
  const { settings } = useContext(SettingsContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [sFormVisible, setSFormVisible] = useState(false);
  const [assignRoleVisible, setAssignRoleVisible] = useState(false);

  const {
    loading,
    TableShimmer,
    Placeholder,
    data: staffData,
    refreshData,
    extra,
  } = useDataFctory("store/staff/" + staffId, false);

  return (
    <div className="mainInnerView">
      <GView
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <GInfo
          id={staffData?.id}
          title={staffData?.title}
          key={staffData?.id}
          className="headerInfo"
          imgType="user"
          imgEdit={false}
          photo={staffData?.photo}
          style={{ width: "auto" }}
        />
      </GView>

      <div style={{ display: "flex", marginTop: "20px", gap: "20px" }}>
        <div className="listPaper" style={{ height: "200px", width: "30%" }}>
          <h4 style={{ display: "flex", margin: "0 0 20px", fontSize: "18px" }}>
            Contact Details
            <GButton
              variant="linkable"
              onClick={() => {
                setSFormVisible(true);
              }}
              style={{ marginLeft: "auto" }}
            >
              <Edit style={{ width: 20, height: 20 }} />
            </GButton>
          </h4>
          <GContent title="Email" value={staffData.email} />
          <GContent title="Phone" value={staffData.phone || "-"} />
        </div>
        <div className="listPaper" style={{ flex: 1 }}>
          <GText g4 bold text={"Logins"} style={{ marginBottom: "20px" }} />

          {loading === true ? (
            <TableShimmer></TableShimmer>
          ) : staffData?.user_logins && staffData?.user_logins.length > 0 ? (
            <GView
              style={{ maxHeight: "calc(100vh - 250px)", overflow: "overlay" }}
            >
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {staffData.user_logins.map((c, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={c.id}
                        >
                          <TableRowData>{c.ip_address}</TableRowData>

                          <TableRowData style={{ textAlign: "center" }}>
                            {c.created || "-"}
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </GView>
          ) : (
            <Placeholder />
          )}
        </div>
      </div>

      <Modal
        isOpen={sFormVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSFormVisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Staff Member Form Modal"
      >
        <StaffForm
          sFormVisible={sFormVisible}
          setSFormVisible={setSFormVisible}
          isEmpty={false}
          data={staffData}
          onSubmit={(data) => {
            refreshData();
          }}
        />
      </Modal>

      <Modal
        isOpen={assignRoleVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAssignRoleVisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Assign Role Form Modal"
      >
        <AssignRoleForm
          assignRoleVisible={assignRoleVisible}
          setAssignRoleVisible={setAssignRoleVisible}
          staffData={staffData}
          onSubmit={() => refreshData()}
        />
      </Modal>
    </div>
  );
}
