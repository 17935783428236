import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import "./assets/css/modal.css";
import "./assets/css/forms.css";
import Login from "./Login";
import Header from "./Parts/header";
import Sidebar from "./Parts/sidebar";
import OrderInvoice from "./Pages/orderInvoice";
import Orders from "./Pages/ordersList";
import OrderDetail from "./Pages/orderDetails";
import OrderThermalInvoice from "./Pages/orderThermalInvoice";
import Products from "./Pages/productsList";
import Categories from "./Pages/categoriesList";
import Reviews from "./Pages/reviewsList";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useMatch,
} from "react-router-dom";
import useSettings from "./useSettings";
import { ThemeStore } from "./DataFactory/useTheme";
import Theme from "./DataFactory/theme";
import Modal from "react-modal";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./errorView";
import NotFound from "./notFound";
import Wallet from "./Pages/wallet";
import Faqs from "./Pages/faqs";
import Promotions from "./Pages/promotionsList";
import StaffMembers from "./Pages/staffMembers";
import StaffDetail from "./Pages/staffDetail";
import HolidayHours from "./Pages/holidayHours";
import Dashboard from "./Pages/dashboard";
import ChatSupport from "./Pages/supportChat";
import StoreDetails from "./Pages/storeDetails";

const contentStyle = {
  background: "#f4f5f9",
  width: "82%",
  height: "100vh",
  overflow: "auto",
};

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}

function App() {
  const location = useLocation();
  const settings = useSettings(true);
  const [toogleSidebar, setToggleSidebar] = useState(false);

  let match = useMatch("/order-invoice/:orderId");
  let match2 = useMatch("/order-thermal-invoice/:orderId");
  let url = match ? match?.pathname : "";
  let tUrl = match2 ? match2?.pathname : "";

  const isLoggedIn = () => {
    if (
      localStorage.getItem("a_m_AccessToken") &&
      localStorage.getItem("a_m_ExpiresIn") > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  function isPermissionExist(per) {
    if (settings?.currency_symbol) {
      let exist = settings?.permissions?.includes(per);
      return exist;
    } else {
      let exist = settings?.permissions?.includes(per);
      return exist;
    }
  }

  Modal.setAppElement("#root");

  useEffect(() => {
    console.log(location);
  }, [location]);

  return (
    <ThemeStore>
      <Theme>
        {isLoggedIn() &&
        !(url === location.pathname || tUrl === location.pathname) ? (
          <div
            className="App"
            id="webElement"
            style={{ display: "inline-flex", width: "100%" }}
          >
            <Sidebar
              style={{
                width: toogleSidebar == true ? "0px" : "18%",
                minWidth: toogleSidebar == true ? "0px" : "175px",
                transition: "width 0.5s ,min-width 0.5s",
              }}
              toggle={toogleSidebar}
            />
            <div
              style={Object.assign({}, contentStyle, {
                width: toogleSidebar == true ? "100%" : "82%",
                transition: "width 0.5s",
              })}
            >
              <Header />
              <ErrorBoundary
                key={location.pathname}
                onError={(error, componentStack) => {
                  console.log(error);
                  console.log(componentStack?.componentStack);
                }}
                FallbackComponent={ErrorFallback}
                onReset={() => {
                  window.location.reload(false);
                }}
              >
                <div
                  className="mainInnerStyles"
                  style={{
                    height: "calc(100vh - 50px)",
                    width: "100%",
                    overflow: "overlay",
                    overflow: "auto",
                    position: "relative",
                  }}
                >
                  {settings?.currency_symbol && !settings?.user?.super ? (
                    <Routes>
                      {isPermissionExist("dashboard_index") ? (
                        <Route
                          path="/*"
                          element={<Navigate replace to="/dashboard" />}
                        />
                      ) : isPermissionExist("order_index") ? (
                        <Route
                          path="/*"
                          element={<Navigate replace to="/dashboard" />}
                        />
                      ) : (
                        <Route
                          path="/*"
                          element={<Navigate replace to="/faqs" />}
                        />
                      )}

                      {isPermissionExist("dashboard_index") && (
                        <Route path="dashboard" element={<Dashboard />}></Route>
                      )}
                      {isPermissionExist("order_index") && (
                        <Route path="orders" element={<Orders />}></Route>
                      )}
                      {isPermissionExist("order_index") && (
                        <Route
                          path="order/:orderId"
                          element={<OrderDetail />}
                        ></Route>
                      )}
                      {isPermissionExist("product_index") && (
                        <Route path="products" element={<Products />}></Route>
                      )}
                      {isPermissionExist("product_index") && (
                        <Route
                          path="categories"
                          element={<Categories />}
                        ></Route>
                      )}
                      {isPermissionExist("coupon_index") && (
                        <Route path="coupons" element={<Promotions />}></Route>
                      )}
                      {isPermissionExist("review_index") && (
                        <Route path="reviews" element={<Reviews />}></Route>
                      )}
                      {isPermissionExist("store_wallet") && (
                        <Route path="wallet" element={<Wallet />}></Route>
                      )}
                      <Route path="faqs" element={<Faqs />}></Route>

                      <Route
                        path="store-details"
                        element={<StoreDetails />}
                      ></Route>
                      <Route
                        path="chat-support"
                        element={<ChatSupport />}
                      ></Route>
                    </Routes>
                  ) : (
                    <Routes>
                      <Route
                        path="/*"
                        element={<Navigate replace to="dashboard" />}
                      />
                      <Route path="dashboard" element={<Dashboard />}></Route>
                      <Route path="orders" element={<Orders />}></Route>
                      <Route
                        path="order/:orderId"
                        element={<OrderDetail />}
                      ></Route>
                      <Route path="products" element={<Products />}></Route>
                      <Route path="categories" element={<Categories />}></Route>
                      <Route
                        path="holiday-hours"
                        element={<HolidayHours />}
                      ></Route>
                      <Route path="coupons" element={<Promotions />}></Route>
                      <Route path="reviews" element={<Reviews />}></Route>
                      <Route path="wallet" element={<Wallet />}></Route>
                      <Route path="faqs" element={<Faqs />}></Route>
                      <Route path="staff" element={<StaffMembers />}></Route>
                      <Route
                        path="staff/:staffId"
                        element={<StaffDetail />}
                      ></Route>
                      <Route
                        path="chat-support"
                        element={<ChatSupport />}
                      ></Route>
                      <Route
                        path="store-details"
                        element={<StoreDetails />}
                      ></Route>
                    </Routes>
                  )}
                </div>
              </ErrorBoundary>
            </div>
          </div>
        ) : (
          <>
            <Routes>
              {location?.pathname !== "/" && (
                <Route path="/*" element={<Navigate replace to="/" />} />
              )}
              <Route exact path="/" element={<Login />}></Route>
              <Route
                path="order-invoice/:orderId"
                element={<OrderInvoice />}
              ></Route>
              <Route
                path="order-thermal-invoice/:orderId"
                element={<OrderThermalInvoice />}
              ></Route>
            </Routes>
          </>
        )}
      </Theme>
    </ThemeStore>
  );
}

export default App;
