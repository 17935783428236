import React, { useEffect, useState, useContext } from "react";
import { ApiService, addDefaultSrc, ImgUrl } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../Elements/gImageEditor";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import ProductVariants from "../Pages/productVariants";
import Modal from "react-modal";
// import LocalizationForm from "../forms/localizationForm";

import { StringsContext } from "../DataFactory/useStrings";

export default function ProductCreateForm(props) {
  const [strings] = useContext(StringsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [productImg, setProductImg] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [variantTypeIndex, setVariantTypeIndex] = useState(0);
  const [productId, setProductId] = useState("");
  const [selectedRelatedItems, setSelectedRelatedItems] = useState([]);
  const [localizationsArray, setLocalizationsArray] = useState([]);
  const [localizationVisible, setLocalizationVisible] = useState(false);

  const initialValues = {
    title: "",
    price: "",
    discount: "",
    unit: "",
    unlimited: "1",
    stock: "0",
    categories: "",
    store_id: props.storeId,
    index: "",
    description: "",
    barcode: "",
    images: [],
    variants: [],
    tax_class_id: "",
    age_restricted: "0",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    values.variants = productVariants;
    values.localizations = localizationsArray;
    values.related =
      selectedRelatedItems.length > 0
        ? selectedRelatedItems.map((p) => p.id)
        : [];
    console.log(values);
    // return;
    ApiService({
      method: "POST",
      route: "products",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setPcvisible(false);
        props.onSubmit();
      }
      setIsBtnDisabled(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    price: Yup.string().required(strings.w_required),
    unit: Yup.string().required(strings.w_required),
    categories: Yup.string().required(strings.w_required),
  });

  if (props.pcvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_add_product}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GText
                semi
                g2
                text={strings.w_basic_information}
                style={{ margin: "0 15px" }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_title}
                name="title"
                divstyle={{
                  display: "inline-block",
                  width: "67%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_price}
                name="price"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("price", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={`${strings.w_discount} (${strings.w_in} %)`}
                name="discount"
                maxLength={3}
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("discount", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "33.3%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_unit}
                name="unit"
                divstyle={{
                  display: "inline-block",
                  width: "33.3%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_index}
                name="index"
                divstyle={{
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "33.3%",
                }}
              />

              {props.categoriesData ? (
                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_category}
                  name="categories"
                  options={props.categoriesData}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    display: "inline-block",
                    verticalAlign: "top",
                    boxSizing: "border-box",
                    width: "50%",
                  }}
                />
              ) : (
                ""
              )}
              {props.settings?.product_barcode_enabled == "1" && (
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_barcode}
                  name="barcode"
                  divstyle={{
                    display: "inline-block",
                    verticalAlign: "top",
                    boxSizing: "border-box",
                    width: "50%",
                  }}
                />
              )}
              <FormikControl
                control="input"
                as="textarea"
                label={strings.w_description}
                name="description"
              />
              {props.settings.product_stock == "1" && (
                <>
                  <FormikControl
                    control="toggle"
                    label={strings.w_unlimited}
                    isToggled={values.unlimited == "1" ? 1 : 0}
                    name="unlimited"
                    onToggle={() => {
                      let unlimitedVal =
                        values.unlimited === "1" ? true : false;
                      unlimitedVal = !unlimitedVal;
                      unlimitedVal = unlimitedVal === true ? "1" : "0";
                      setFieldValue("unlimited", unlimitedVal);
                    }}
                    style={{ width: "25%", boxSizing: "border-box" }}
                  />
                  {values.unlimited != "1" ? (
                    <FormikControl
                      control="input"
                      type="text"
                      label={strings.w_stock}
                      name="stock"
                      divstyle={{
                        display: "inline-block",
                        width: "35%",
                        boxSizing: "border-box",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
              {props.settings?.tax_class_enabled == "1" ? (
                <FormikControl
                  control="select"
                  as="select"
                  label={strings.w_tax_class}
                  name="tax_class_id"
                  placeholder={strings.w_choose_tax_class}
                  options={props.taxClasses || []}
                  key_title="title"
                  key_value="id"
                  divstyle={{
                    display: "inline-block",
                    verticalAlign: "top",
                    boxSizing: "border-box",
                    width: "40%",
                  }}
                />
              ) : (
                ""
              )}
              <FormikControl
                control="toggle"
                label={strings.w_age_restricted}
                isToggled={values.age_restricted == "1" ? 1 : 0}
                name="age_restricted"
                onToggle={() => {
                  let ageRestricted =
                    values.age_restricted === "1" ? true : false;
                  ageRestricted = !ageRestricted;
                  ageRestricted = ageRestricted === true ? "1" : "0";
                  setFieldValue("age_restricted", ageRestricted);
                }}
                style={{ width: "30%", boxSizing: "border-box" }}
              />

              {props.settings?.locales &&
                props.settings?.locales.length > 0 && (
                  <div style={{ margin: "15px 15px" }}>
                    <label>Localizations</label>

                    {localizationsArray.length > 0 ? (
                      <GButton
                        variant="linkable"
                        onClick={() => setLocalizationVisible(true)}
                      >
                        {localizationsArray.length} Localizations added
                      </GButton>
                    ) : (
                      <GButton
                        variant="linkable"
                        children="+ Add Localization"
                        onClick={() => setLocalizationVisible(true)}
                      />
                    )}
                  </div>
                )}

              <div style={{ margin: "15px" }}>
                <GText
                  semi
                  g2
                  text={"Product Images"}
                  style={{ MarginBottom: "5px" }}
                />

                {productImg.map((b, ind) => {
                  return (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                      key={ind}
                    >
                      <img
                        alt=""
                        src={ImgUrl("item") + "/" + b.photo}
                        onError={addDefaultSrc}
                        style={{
                          width: "180px",
                          height: "140px",
                          padding: "5px",
                          border: "1px solid #e2e2e2",
                          objectFit: "cover",
                          margin: "5px",
                        }}
                      />
                      <Delete
                        style={{
                          background: "#000",
                          fontSize: "1rem",
                          width: 20,
                          height: 20,
                          fill: "#fff",
                          padding: "3px",
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                        }}
                        onClick={(e) => {
                          productImg.splice(ind, 1);
                          setProductImg([...productImg]);
                          values.images = [...productImg];
                        }}
                      />
                    </div>
                  );
                })}

                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    setProductImg([
                      ...productImg,
                      { photo: newValue, thumb_photo: thumb_val },
                    ]);
                    values.images = [
                      ...productImg,
                      { photo: newValue, thumb_photo: thumb_val },
                    ];
                  }}
                  id="thumb_photo"
                  image={
                    values.photo ? ImgUrl("item") + "/" + values.photo : ""
                  }
                  type="item"
                  style={{ height: "140px", paddingTop: "50px" }}
                  divstyle={{
                    margin: "0 auto",
                    display: "inline-flex",
                    minWidth: "220px",
                  }}
                />
              </div>

              {props.variantTypes && props.variantTypes.length > 0 && (
                <div style={{ margin: "15px" }}>
                  <GText semi g2 text={"Product Variants"} />
                  <div style={{ background: "#f8f9fd", padding: "15px 5px" }}>
                    <ProductVariants
                      setVariantTypeIndex={setVariantTypeIndex}
                      variantTypes={props.variantTypes}
                      selectedIndex={variantTypeIndex}
                      handleVariantAdd={(index, data) => {
                        setVariantTypeIndex(index);
                        productVariants.push(data);
                      }}
                      handleVariantEdit={(index, data) => {
                        setVariantTypeIndex(index);
                        let selectedInd = productVariants.findIndex(
                          (p) => p.variant_id == data.variant_id
                        );
                        productVariants[selectedInd] = data;
                        setProductVariants([...productVariants]);
                      }}
                      handleVariantRemove={(index, i) => {
                        setVariantTypeIndex(index);
                        console.log(i);
                        productVariants.splice(i, 1);
                        setProductVariants([...productVariants]);
                      }}
                      isVarStockShow={props.isStockShow}
                    />
                  </div>
                </div>
              )}

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children="Create"
                style={{ width: "calc(100% - 30px)", margin: "15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>

      {/* <Modal
        isOpen={localizationVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setLocalizationVisible(false)}
        style={{
          content: {
            width: "600px",
          },
        }}
        contentLabel="Localization Form Modal"
      >
        <LocalizationForm
          localizationVisible={localizationVisible}
          setLocalizationVisible={setLocalizationVisible}
          data={localizationsArray}
          onSubmit={(data) => {
            setLocalizationsArray([...data]);
          }}
          keyArray={
            props.localizableKeys || [
              { title: "Title", value: "title" },
              { title: "Unit", value: "unit" },
            ]
          }
          variables={[]}
        />
      </Modal> */}
    </>
  );
}
