import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import styled from "styled-components";
import GText from "../Elements/GText";

const DurationDiv = styled.div`
  padding: 4px 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  cursor: pointer;
`;

export default function StoreAvailabilityForm(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showReasonView, setShowReasonView] = useState(false);

  const initialValues = {
    duration: "",
    reason: "",
  };

  const DurationOptions = [
    { title: "10 min", value: "10" },
    { title: "15 min", value: "15" },
    { title: "20 min", value: "20" },
    { title: "25 min", value: "25" },
    { title: "Other", value: "other" },
  ];

  const ReasonOptions = [
    { title: "Closing Early" },
    { title: "Problem in Restaurant" },
    { title: "Out of Item(s)" },
    { title: "Other" },
  ];

  const onSubmit = (values) => {
    //setIsDisabled(true);

    let sendData = {};
    sendData.duration =
      values.duration == "other" ? values.other_duration : values.duration;
    sendData.reason =
      values.reason == "Other" ? values.other_reason : values.reason;
    console.log(sendData);
    // return;
    ApiService({ method: "PUT", route: "pause-orders", body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setShowSAvaiChangeForm(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    duration: Yup.string().required("Required"),
    reason: Yup.string().required("Required"),
  });

  if (props.showSAvaiChangeForm === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">Pause New Orders</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {showReasonView === true ? (
                <div>
                  <GText
                    semi
                    text={"Please select a reason for pausing"}
                    style={{ margin: "0 15px" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px 15px",
                      margin: "15px 15px 0",
                    }}
                  >
                    {ReasonOptions.map((d, i) => {
                      return (
                        <DurationDiv
                          key={i}
                          onClick={() => {
                            setFieldValue("reason", d.title);
                            if (d.title != "Other") {
                              setFieldValue("other_reason", "");
                            }
                          }}
                          style={{
                            background:
                              values.reason == d.title ? "#000" : "transparent",
                            color: values.reason == d.title ? "#fff" : "#000",
                          }}
                        >
                          {d.title}
                        </DurationDiv>
                      );
                    })}
                  </div>

                  <FormikControl
                    control="input"
                    type="text"
                    name="reason"
                    style={{ display: "none" }}
                  />

                  {values.reason === "Other" && (
                    <FormikControl
                      control="input"
                      type="text"
                      placeholder="Anything else to add?"
                      maxLength={4}
                      name="other_reason"
                    />
                  )}
                </div>
              ) : (
                <>
                  <GText
                    semi
                    text={"How long would you like to pause new orders?"}
                    style={{ margin: "0 15px" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px 15px",
                      margin: "15px 15px 0",
                    }}
                  >
                    {DurationOptions.map((d, i) => {
                      return (
                        <DurationDiv
                          key={i}
                          onClick={() => {
                            setFieldValue("duration", d.value);
                            if (d.value != "other") {
                              setFieldValue("other_duration", "");
                            }
                          }}
                          style={{
                            background:
                              values.duration == d.value
                                ? "#000"
                                : "transparent",
                            color: values.duration == d.value ? "#fff" : "#000",
                          }}
                        >
                          {d.title}
                        </DurationDiv>
                      );
                    })}
                  </div>
                  <FormikControl
                    control="input"
                    type="text"
                    name="duration"
                    style={{ display: "none" }}
                  />
                  {values.duration === "other" && (
                    <FormikControl
                      control="input"
                      type="text"
                      placeholder="50"
                      maxLength={4}
                      name="other_duration"
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("other_duration", event.target.value);
                        }
                      }}
                    />
                  )}
                </>
              )}

              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <GButton
                  type="button"
                  variant="linkable"
                  children={"Cancel"}
                  style={{ margin: "0 15px", width: "auto" }}
                  onClick={() => props.setShowSAvaiChangeForm(false)}
                />
                {showReasonView === true ? (
                  <GButton
                    type="submit"
                    disabled={isDisabled}
                    variant="condensed"
                    children={"Submit"}
                    style={{ margin: "0 15px", width: "auto" }}
                  />
                ) : (
                  <GButton
                    type="button"
                    disabled={
                      values.duration
                        ? values.duration !== "other"
                          ? false
                          : values.other_duration
                          ? false
                          : true
                        : true
                    }
                    variant="condensed"
                    children={"Next"}
                    style={{ margin: "0 15px", width: "auto" }}
                    onClick={() => {
                      setShowReasonView(true);
                    }}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
