import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { Table, TableBody, TableRowData } from "../styledComponents/tableStyle";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { ReactComponent as Remove } from "../assets/images/icons/delete.svg";
import { ReactComponent as CheckIcon } from "../assets/images/check.svg";
import GInput from "./gInput";
import GButton from "./GButton";
const Small = styled.small`
  font-size: 0.8rem;
  font-weight: 400;
  display: inline-block;
  padding-right: 5px;
  /* display: block; */
`;

const Tr = styled.tr`
  td {
    border-bottom: 1px solid #f2f2f2;
  }
`;

function GItemTable(props) {
  const [eitems, setEItems] = useState([...props.items] || []);
  const [editableQuant, setEditableQuant] = useState();

  useEffect(() => {
    console.log(props.items);
    props.items.map((item) => {
      item.editable_view = false;
    });
    console.log(props.items);
    setEItems([...props.items]);
  }, [props.items]);

  function markAsPacked(item) {
    props.onMarkPacked(item);
  }

  return (
    <Table className="table">
      <TableBody>
        {eitems.map((i, ind) => {
          return (
            <Tr key={i.id}>
              <td style={{ width: "80px" }}>
                <img
                  alt=""
                  src={ImgUrl("item") + "/" + i.thumb_photo}
                  onError={(e) => addDefaultSrc(e, "item")}
                  className="thumbnail"
                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                />
              </td>
              {/* <td style={{ width: "30px" }}>{i.quantity} &nbsp;X</td> */}
              <TableRowData>
                {i.title}
                {/* <i>({i.category.title})</i> */}
                <br />
                {(i.variants_available === true || i.variants) &&
                  i.variants.map((v, $index) => {
                    return (
                      <Small key={v.id}>
                        {v.title}
                        {v.value ? (
                          <span style={{ color: "#777" }}> : {v.value}</span>
                        ) : (
                          ""
                        )}
                        {i.variants.length > $index + 1 ? <span>, </span> : ""}
                      </Small>
                    );
                  })}
              </TableRowData>
              <TableRowData style={{ minWidth: "80px" }}>
                {props.currencySymbol}
                {i.price_display ? i.price_display : i.price} * {i.quantity}
              </TableRowData>

              {props.editable === true && (
                <TableRowData style={{ textAlign: "right" }}>
                  <div
                    style={{
                      border: "1px solid #d8ae36",
                      display: "inline-flex",
                      color: "#d8ae36",
                      padding: "5px",
                      position: "relative",
                    }}
                  >
                    <Remove
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      onClick={() => props.onDecQuantity(i)}
                    />
                    <span
                      style={{
                        padding: "0 8px",
                        cursor: props.decimalQuantity ? "pointer" : "auto",
                      }}
                      onClick={() => {
                        if (props.decimalQuantity) {
                          eitems
                            .filter((f) => f.id != i.id)
                            .map((e) => {
                              e.editable_view = false;
                            });
                          i.editable_view = true;
                          eitems[ind].editable_view = true;
                          setEItems([...eitems]);
                          setEditableQuant(i.quantity);
                        }
                      }}
                    >
                      {i.quantity}
                    </span>
                    {eitems[ind].editable_view == true && (
                      <div
                        style={{
                          position: "absolute",
                          top: "35px",
                          zIndex: 1,
                          width: "150px",
                          background: "#fff",
                          border: "1px solid #e2e2e2",
                          padding: "10px",
                          boxShadow: "0 2px 5px rgb(0 0 0 / 25%)",
                        }}
                      >
                        <GInput
                          type="text"
                          placeholder=""
                          name="quntity"
                          id="quntity"
                          value={editableQuant}
                          display="block"
                          onChange={(e) => {
                            setEditableQuant(e.target.value || "");
                          }}
                          style={{ width: "120px" }}
                        />

                        <GButton
                          variant="linkable"
                          children="Submit"
                          onClick={() => {
                            eitems[ind].editable_view = false;
                            eitems[ind].quantity = editableQuant;
                            i.quantity = editableQuant;
                            setEItems([...eitems]);
                            props.decimalQuantity(i);
                          }}
                        />
                        <GButton
                          variant="linkable"
                          children="Cancel"
                          onClick={() => {
                            eitems[ind].editable_view = false;
                            setEItems([...eitems]);
                          }}
                          style={{ color: "#a2a2a2" }}
                        />
                      </div>
                    )}
                    <Add
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      onClick={() => props.onIncQuantity(i)}
                    />
                  </div>
                </TableRowData>
              )}
              <TableRowData style={{ textAlign: "right" }}>
                {props.currencySymbol}
                {(parseFloat(i.price) * i.quantity).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </TableRowData>
              {props.editable === true && (
                <TableRowData style={{ textAlign: "right" }}>
                  <Delete
                    style={{
                      fontSize: "18px",
                      cursor: "pointer",
                      color: "#d8ae36",
                    }}
                    onClick={() => props.onRemove(i)}
                  />
                </TableRowData>
              )}

              {props.isPacked === true && (
                <TableRowData
                  style={{
                    textAlign: "center",
                    width: "60px",
                    paddingRight: "5px",
                  }}
                >
                  {i.packed == 1 ? (
                    <span
                      style={{
                        fontStyle: "italic",
                        background: "#d8ae36",
                        color: "#fff",
                        padding: "2px 6px",
                        fontSize: "12px",
                      }}
                    >
                      Packed
                    </span>
                  ) : (
                    <GButton
                      variant="linkable"
                      style={{ fontSize: "14px" }}
                      onClick={() => markAsPacked(i)}
                      title="Mark as Packed"
                    >
                      {/* Mark as packed */}
                      <CheckIcon
                        title="Mark as Packed"
                        style={{ width: 20, height: 20 }}
                      />
                    </GButton>
                  )}
                </TableRowData>
              )}
            </Tr>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default GItemTable;
