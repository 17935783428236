import React, { useState, useEffect } from "react";
import "../assets/css/invoice.css";
import { ApiService, ImgUrl, addDefaultLogo } from "../services";
import useSettings from "../useSettings";
import { useParams } from "react-router-dom";

export default function OrderThermalInvoice() {
  const setting = useSettings();
  let { orderId } = useParams();
  const [invoiceData, setInvoiceData] = useState({});

  useEffect(() => {
    const getOrderDetail = () => {
      ApiService({ method: "GET", route: "orders/" + orderId })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setInvoiceData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (localStorage.getItem("a_m_AccessToken")) {
      getOrderDetail();
    } else {
      window.location = "/";
    }
  }, []);

  return (
    <div className="thermalInvoiceDiv">
      <img
        src={ImgUrl("logo") + "/" + setting.invoice_logo}
        alt="logo"
        onError={addDefaultLogo}
      />
      <hr />

      <div>
        <p>{setting.invoice_header_text}</p>
        <h4>Order no.</h4>
        <p>{"#" + orderId}</p>
        <p>{invoiceData.created ? invoiceData.created_full : ""}</p>
        <br />
        <h4>Customer</h4>
        <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
        <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
        <p>
          {invoiceData.delivery ? invoiceData.delivery.delivery_address : ""}
        </p>
        <hr />
        <h4>Store</h4>
        <p>{setting?.store?.title || ""}</p>
        <p>{setting.store?.address || ""}</p>
        {setting.product !== "laundry" && (
          <>
            <hr />
            <p>
              Delivery Type -{" "}
              {invoiceData.delivery_type ? invoiceData.delivery_type : ""}
            </p>
          </>
        )}
        <hr />
        <h4>
          Items (
          {invoiceData.order_products ? invoiceData.order_products.length : ""})
        </h4>
      </div>

      <div>
        <table>
          {invoiceData.order_products ? (
            <tbody>
              {invoiceData.order_products.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{item.quantity}X</td>
                    <td>
                      {item.title}
                      <br />({item.price}
                      {item.unit ? "/" + item.unit : ""})
                      <br />
                      {item.variants.map((v, $index) => {
                        return (
                          <small
                            key={v.id}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              display: "inline-block",
                              paddingRight: "5px",
                              lineHeight: "20px",
                              color: "#333",
                            }}
                          >
                            {v.value}
                            {item.variants.length > $index + 1 ? (
                              <span>, </span>
                            ) : (
                              ""
                            )}
                          </small>
                        );
                      })}
                    </td>
                    <td style={{ textAlign: "right", verticalAlign: "bottom" }}>
                      {setting?.currency_symbol}
                      {(item.quantity * item.price).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            ""
          )}
        </table>

        <table style={{ borderTop: "0.5px solid #ccc" }}>
          {invoiceData.payment_summary ? (
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td
                  style={{ textAlign: "right", verticalAlign: "bottom" }}
                ></td>
              </tr>
              {invoiceData.payment_summary.data.map((payment, ind) => {
                return payment.title !== "line" ? (
                  <tr className={payment.title + " payment"} key={ind}>
                    <td>{payment.title}</td>
                    <td></td>
                    <td style={{ textAlign: "right" }}>{payment.value}</td>
                  </tr>
                ) : (
                  <tr style={{ borderBottom: "1px solid #e2e2e2" }}>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    ></td>
                  </tr>
                );
              })}

              <tr
                className="payment"
                style={{ borderTop: "1px solid #e2e2e2" }}
              >
                <td>Total ({invoiceData.gateway_display})</td>
                <td></td>
                <td style={{ textAlign: "right" }}>
                  {setting?.currency_symbol}
                  {invoiceData.payment_summary.total_display}
                </td>
              </tr>
            </tbody>
          ) : (
            ""
          )}
        </table>
      </div>
      <br />

      <p>Thank you for using the services of {setting?.project_name}.</p>
      <p>Powered by Ambar</p>
      <br />
      <p>{setting.invoice_footer_text}</p>
    </div>
  );
}
