import React, { useState, useEffect, useRef } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { ReactComponent as Close } from "../assets/images/close.svg";
import { ReactComponent as Search } from "../assets/images/search.svg";
import GText from "../Elements/GText";
import { ReactComponent as ArrowDropDown } from "../assets/images/arrowDown.svg";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import useDebounce from "../DataFactory/useDebounce";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #d8ae36;
    color: #fff;
  }
`;

const Input = styled.input`
  width: calc(100% - 50px);
  /* min-width: 350px; */
  // max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const ProductFilter = ({
  value,
  status,
  handleProductChange,
  handleBlur,
  handleFocus,
  hostId,
  placeholder,
  style,
  innerStyle,
}) => {
  const [searchWord, setSearchWord] = useState("");
  const debouncedSearchTerm = useDebounce(searchWord, 500);
  const wrapperSRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [productsFilterList, setProducts] = useState([]);

  useEffect(() => {
    var hitUrl =
      "products?per_page=100&search=" +
      searchWord +
      "&status=" +
      (status || "");
    ApiService({ method: "GET", route: hitUrl })
      .then((response) => {
        console.log("gh" + response);
        if (response.data.status_code === 1) {
          setProducts(response.data.data.data);
          response.data.data.data.map((values) => {
            if (values.id == value) {
              setSelectedTitle(values?.title);
            }
          });
          //setSelectedValue(value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [debouncedSearchTerm, hostId]);

  useEffect(() => {
    if (!value) {
      setSelectedTitle("");
    }
  }, [value]);

  return (
    <div
      ref={wrapperSRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          verticalAlign: "middle",
          position: "relative",
        },
        style
      )}
    >
      <div
        style={Object.assign(
          {
            background: "#fff",
            border: "1px solid rgb(226, 226, 226)",
            height: "40px",
            fontSize: "0.97rem",
            color: "#555",
            boxSizing: "border-box",
            padding: "10px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          },
          innerStyle
        )}
        onClick={() => {
          setShowFilterList(true);
          setSearchWord("");
        }}
      >
        {selectedTitle ? (
          <span
            style={{
              width: "calc(100% - 40px)",
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              height: "20px",
              verticalAlign: "top",
            }}
          >
            {selectedTitle}
          </span>
        ) : (
          <span style={{ color: "#555" }}>{placeholder || "All Products"}</span>
        )}

        {selectedTitle ? (
          <Close
            fontSize="small"
            style={{
              marginLeft: "auto",
              width: "12px",
              height: "12px",
              fill: "#777",
              cursor: "pointer",
            }}
            onClick={() => {
              handleProductChange();
              setSelectedTitle();
            }}
          />
        ) : (
          ""
        )}
        <ArrowDropDown
          style={{
            marginLeft: selectedTitle ? "6px" : "auto",
            width: "10px",
            height: "19px",
            fill: "#555",
            marginRight: "5px",
          }}
        />
      </div>
      {showFilterList === true ? (
        <div
          style={Object.assign(
            {},
            {
              background: "#fff",
              position: "absolute",
              zIndex: "9",
              width: "200px",
              padding: "0 5px 5px",
              border: "1px solid rgb(226, 226, 226)",
              boxSizing: "border-box",
            },
            style
          )}
        >
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                width: "15px",
                height: "15px",
                fill: "#ccc",
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              maxHeight: "220px",
              overflow: "auto",
            }}
          >
            {productsFilterList && productsFilterList.length > 0 ? (
              productsFilterList.map((c, i) => {
                return (
                  <LI
                    key={i}
                    onClick={() => {
                      setSelectedTitle(c?.title);
                      setShowFilterList(false);
                      handleProductChange(c.id, c);
                    }}
                  >
                    {c.title || c?.custom_fields?.title}
                  </LI>
                );
              })
            ) : (
              <GText med text="No Data Found" style={{ padding: "10px" }} />
            )}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
