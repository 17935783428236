import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import useDateFormatter from "../DataFactory/useDateFormatter";
import styled from "styled-components";
import { ReactComponent as CalendarIcon } from "../assets/images/calendar.svg";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import GText from "../Elements/GText";
import GDateTimePicker from "../Elements/gDateTimePicker";

const SuggestionDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  background: #e2e2e2;
  padding: 8px 12px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
`;

export default function TimeslotsForm(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    starts: props.data ? props.data.starts : "",
    ends: props.data ? props.data.ends : "",
  };

  const onSubmit = (values) => {
    // setIsDisabled(true);

    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "holiday_hours/" + props.data.id
      : "holiday_hours";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setWhvisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    starts: Yup.string().required("Required"),
    ends: Yup.string().required("Required"),
  });

  if (props.whvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Add"} Holiday Hour
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div style={{ position: "relative", padding: "10px 15px" }}>
                <label>Start Time</label>
                <GDateTimePicker
                  value={values.starts}
                  timezone={"current"}
                  onChange={(v) => setFieldValue("starts", v || "")}
                />
                <FormikControl
                  control="input"
                  type="text"
                  name="starts"
                  style={{ display: "none" }}
                  divstyle={{ margin: 0, padding: 0 }}
                />
              </div>

              <div style={{ position: "relative", padding: "10px 15px" }}>
                <label>End Time</label>
                <GDateTimePicker
                  value={values.ends}
                  timezone={"current"}
                  onChange={(v) => setFieldValue("ends", v || "")}
                />
                <FormikControl
                  control="input"
                  type="text"
                  name="ends"
                  style={{ display: "none" }}
                  divstyle={{ margin: 0, padding: 0 }}
                />
              </div>
              <br />
              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={!props.isEmpty ? "Update" : "Create"}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
