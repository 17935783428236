import React, { useEffect, useState } from "react";
import GImageEditor from "../Elements/gImageEditor";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import GConfirmDialog from "../Elements/gConfirmDialog";
var pImageStyle = {
  width: "180px",
  height: "140px",
  padding: "5px",
  border: "1px solid #e2e2e2",
  objectFit: "cover",
  margin: "5px",
};
var imageIconStyle = {
  background: "#000",
  width: "18px",
  fill: "#fff",
  padding: "3px",
  position: "absolute",
  right: "10px",
  top: "10px",
};

export default function ProductImagesView(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState("");
  const [propductImg, setProductImg] = useState(props.productImages);
  useEffect(() => {
    setProductImg(props.productImages);
  }, [props]);

  const handleImageUpload = (newVal, id, thumbVal) => {
    var imagesVal = {
      product_id: props.productId,
      photo: newVal,
      thumb_photo: thumbVal,
    };
    console.log(imagesVal);
    ApiService({
      method: "POST",
      route: "product/images",
      body: imagesVal,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.onUpload(response.data.data);
        //setProductImgaes([...productImages, response.data]);
      }
    });
  };

  const deleteProductImage = () => {
    ApiService({
      method: "DELETE",
      route: "product/images/" + selectedImageId,
    }).then((response) => {
      console.log(response.data.status_code);
      if (response.data.status_code === 1) {
        props.onRemove(selectedImageId);
        //setProductImgaes([...productImages, response.data]);
      }
    });
  };

  return (
    <React.Fragment>
      {propductImg.map((b, ind) => {
        return (
          <div
            style={{ position: "relative", display: "inline-block" }}
            key={ind}
          >
            <img
              alt=""
              src={ImgUrl("item") + "/" + b.photo}
              onError={addDefaultSrc}
              style={pImageStyle}
            />
            <Delete
              style={imageIconStyle}
              onClick={(e) => {
                setSelectedImageId(b.id);
                setDialogOpen(true);
              }}
            />
          </div>
        );
      })}

      {props.add === true && (
        <GImageEditor
          onSubmit={handleImageUpload}
          id="thumb_photo"
          type="item"
          image=""
          edit={false}
          style={{ height: "150px", paddingTop: "60px", margin: "0" }}
          divstyle={{
            width: "180px",
            minWidth: "150px",
            display: "inline-block",
            padding: "0",
            margin: "5px",
          }}
        />
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Poduct Image"
          text="Are you sure you want to delete this Product Image?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteProductImage();
          }}
        ></GConfirmDialog>
      )}
    </React.Fragment>
  );
}
