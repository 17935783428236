import React, { useRef, useState } from "react";
import styled from "styled-components";
import GDropdown from "./gDropdown";
import { useSnackbar } from "notistack";
import GButton from "./GButton";
import GInfo from "./gInfo";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
// import { AddCircleOutline } from "@material-ui/icons";
// import AssignDriverPopup from "../components/assignDriver";
import AssigningIcon from "../assets/images/wall-clock-white.svg";
import { ApiService } from "../services";
import GText from "./GText";

const AgentDiv = styled.div`
  margin-bottom: 10px;
  small {
    color: #777;
    font-style: italic;
    display: block;
    padding: 5px 10px;
  }
`;

export default function GCancelBox(props) {
  return (
    <AgentDiv>
      {props.isAssign === false ? (
        <React.Fragment>
          <GText
            dark
            text={"No driver assigned yet"}
            style={{ fontStyle: "italic" }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {" "}
          {props.taskLog ? (
            <React.Fragment>
              <p
                style={{
                  color: "#fff",
                  fontStyle: "italic",
                  background: "#d8ae36",
                  padding: "5px",
                  display: "inline-block",
                  fontFamily: "LatoWebSemibold",
                  margin: "10px 0 15px",
                }}
              >
                <img
                  src={AssigningIcon}
                  alt="Task"
                  style={{ width: "20px", verticalAlign: "middle" }}
                />{" "}
                &nbsp;&nbsp;Assigning ({props.taskLog.current_count} of{" "}
                {props.taskLog.retries_count}) - {props.taskLog.expire_time}
                sec each
              </p>
              <GInfo
                title={props.driverData.title + " (current)"}
                key={props.driverData.id}
                subtitle={props.driverData.phone}
                photo={props.driverData.thumb_photo}
                imgType="user"
              />
            </React.Fragment>
          ) : (
            <GInfo
              title={props.driverData.title}
              key={props.driverData.id}
              subtitle={props.driverData.phone}
              photo={props.driverData.thumb_photo}
              imgType="user"
            />
          )}
        </React.Fragment>
      )}
    </AgentDiv>
  );
}
